import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PricingData from "../../data/pricing/PricingData.json";
import { FaCheck } from "react-icons/fa";

const getPricingData = PricingData;

const billed = [
    {
        id: 1,
        label: "Annual Payment"
    },
    {
        id: 2,
        label: "Monthly Payment"
    }
];

const PricingOne = () => {
    const [isActive, setActive] = useState("monthly payment"); // Set default to "monthly payment"
    const [selectedLink, setSelectedLink] = useState(""); // New state to track the selected link

    useEffect(() => {
        setActive(billed[1].label.toLowerCase()); // Set default to "monthly payment"
    }, []);

    const handleChange = (e) => {
        setActive(e.target.textContent.toLowerCase());
        setSelectedLink(""); // Reset the selected link when the duration changes
    };

    const handleStartNowClick = () => {
        // Check the selected link and determine the appropriate behavior
        if (selectedLink === "Annual Payment") {
            window.location.href = getPricingData[0].yearlyLink; // Use window.location.href to open an external link
        } else if (selectedLink === "Monthly Payment") {
            window.location.href = getPricingData[0].monthlyLink; // Use window.location.href to open an external link
        } else {
            console.log("Link not specified");
        }
    };

    return (
        <>
            <div className="pricing-billing-duration">
                <ul>
                    {billed.map((data) => (
                        <li className="nav-item" key={data.id}>
                            <button onClick={handleChange} className={`nav-link ${data.label.toLowerCase() === isActive ? "active" : ""}`}>
                                {data.label}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="row">
                {getPricingData.map((data, index) => (
                    <div className="col-lg-4" key={index}>
                        <div className="pricing-table pricing-borderd">
                            <div className="pricing-header">
                                <h3 className="title">{data.title}</h3>
                                <span className="subtitle">{data.subtitle}</span>
                                <div className="price-wrap">
                                    <div className="yearly-pricing">
                                        <span className="amount">{billed[0].label.toLowerCase() === isActive ? data.yprice : data.mprice}</span>
                                        <span className="duration">{billed[0].label.toLowerCase() === isActive ? data.yduration : data.mduration}</span>
                                    </div>
                                </div>
                                <div className="pricing-btn">
                                    <a href={billed[0].label.toLowerCase() === isActive ? data.yearlyLink : data.monthlyLink} className="axil-btn btn-large btn-borderd" onClick={() => setSelectedLink(isActive)}>
                                        Start Now
                                    </a>
                                </div>
                            </div>
                            <div className="pricing-body">
                                <ul className="list-unstyled">
                                    {data.facility.map((facility, index) => (
                                        <li key={index}><FaCheck /> {facility}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default PricingOne;
