import React from 'react';
import FormOne from '../contact/FormOne';
import Accordion from 'react-bootstrap/Accordion';
import { FaCompress, FaCode, FaGlobe } from 'react-icons/fa';

const AboutTwo = () => {
    return (
        <div className="section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="why-choose-us">
                            <div className="section-heading heading-left">
                                <span className="subtitle">Who Are We?</span>
                                <h3 className="title">Why is the Brand Important?</h3>
                                <p>The brand is a unique representation and distinctive definition of a company or product in the minds of customers. The brand is important for several reasons.</p>
                            </div>
                            <Accordion defaultActiveKey="1">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><FaCompress /> Differentiation and Uniqueness</Accordion.Header>
                                    <Accordion.Body>
                                        The brand helps differentiate your company or product uniquely among competitors. When customers recognize and connect with your brand, they are more likely to choose your products or services over the competition.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header><FaCode /> Building Trust and Loyalty</Accordion.Header>
                                    <Accordion.Body>
                                        The brand helps build trust between you and your customers. With a strong and positive brand identity, customers are more willing to do business with you and stay with you in the long run.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header><FaGlobe /> Effective Marketing</Accordion.Header>
                                    <Accordion.Body>
                                        The brand facilitates marketing operations. With a clear brand identity, you can better direct marketing strategies, whether through social media, advertising, or promotional campaigns.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 offset-xl-1">
                        <div className="contact-form-box shadow-box mb--30">
                            <h3 className="title">Get a Free Quote Today</h3>
                            <FormOne />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutTwo;
