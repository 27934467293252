function flatDeep(arr, d = 1) {
  return d > 0 ? arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val), [])
               : arr.slice();
}

const slugify = function (text) {
  if (text && text.toString) {
      return text
          .toString()
          .toLowerCase()
          .replace(/\s+/g, '-'); // Replace spaces with -
  }
  return '';
}





function containsObject(obj, list) {
  var i;
  for (i = 0; i < list.length; i++) {
  console.log()
      if (list[i].slug === obj.slug) {
          return i;
      }
  }

  return -1;
}


export {flatDeep, slugify, containsObject};