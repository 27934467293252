import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaPinterestP, FaLinkedin, FaInstagram, FaVimeoV, FaDribbble, FaBehance, FaEnvelopeOpen, FaTiktok } from "react-icons/fa";
import ServiceData from "../../data/service/ServiceMain.json";
import { slugify } from '../../utils';
import axios from 'axios'; // Import Axios for making API requests

const getServiceData = ServiceData;

const FooterOne = ({ parentClass }) => {
  const [email, setEmail] = useState('');
  const [subscriptionMessage, setSubscriptionMessage] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const subscribeToMailchimp = async () => {
    // Replace with your Mailchimp API key and Audience ID
    const apiKey = '54112f684389b27d50dfe0cbb8ea6243-us21';
    const audienceId = '4831474d7f';
  
    const data = {
      email_address: email,
      status: 'subscribed', // 'subscribed' to add the user as a subscriber
    };
  
    try {
      const response = await axios.post(
        `https://us21.api.mailchimp.com/3.0/lists/${audienceId}/members`,
        data,
        {
          headers: {
            'Authorization': `Basic ${Buffer.from(`apikey:${apiKey}`).toString('base64')}`,
          },
        }
      );
  
      if (response.status === 200) {
        setSubscriptionMessage(' You have successfully subscribed');
      } else {
        setSubscriptionMessage(' Failed Subscription.');
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.title) {
        setSubscriptionMessage('Error: ' + error.response.data.title);
      } else {
        setSubscriptionMessage('Error');
      }
    }
  };
    return (
        <footer className={`footer-area ${parentClass}`}>
            <div className="container">
                <div className="footer-top">
                    <div className="footer-social-link">
                    <ul className="list-unstyled">
                        <li><a href="https://facebook.com/bkatbi" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a></li>
                        <li><a href="https://twitter.com/katbi89" target="_blank" rel="noopener noreferrer"><FaTwitter /></a></li>
                        <li><a href="https://www.linkedin.com/in/katbi89" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a></li>
                        <li><a href="https://www.instagram.com/katbidotnet" target="_blank" rel="noopener noreferrer"><FaInstagram /></a></li>
                        <li><a href="https://www.tiktok.com/en/katbidotnet" target="_blank" rel="noopener noreferrer"><FaTiktok /></a></li>
                     </ul>
                    </div>
                </div>
                <div className="footer-main">
                    <div className="row">
                        <div className="col-xl-6 col-lg-5">
                            <div className="footer-widget border-end">
                                <div className="footer-newsletter">
                                    <h2 className="title">Stay on Touch</h2>
                                    <p>To receive the latest technical news and offers</p>
                                    <form onSubmit={(e) => { e.preventDefault(); subscribeToMailchimp(); }}>
                                        <div className="input-group">
                                            <span className="mail-icon"><FaEnvelopeOpen /></span>
                                            <input
                                            type="email"
                                            className="form-control"
                                            placeholder="E-Mail"
                                            value={email}
                                            onChange={handleEmailChange}
                                            />
                                            <button className="subscribe-btn" type="submit">Subscribe</button>
                                        </div>
                                    </form>
                                    <p>{subscriptionMessage}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-7">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">My Services</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                {getServiceData.slice(0, 6).map((data, index) => (
                                                    <li key={index}>
                                                            <Link to={process.env.PUBLIC_URL + `/service-details/${slugify(data.title)}`}>{data.title}</Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Resources</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><Link to={process.env.PUBLIC_URL + "/blog"}>Blog</Link></li>
                                                <li> <Link to={process.env.PUBLIC_URL + "/case-study"}>Case Study</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + "/project-grid-one"}>Gallery</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Support</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link></li>
                                                <li> <Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + "/terms-use"}>Terms Conditions</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom"> 
                    <div className="row">
                        <div className="col-md-6">
                            <div className="footer-copyright">
                                <span className="copyright-text">© {new Date().getFullYear()} All Copyright Reserved</span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="footer-bottom-link">
                                <ul className="list-unstyled">
                                    <li><Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/terms-use"}> Terms Conditions</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterOne;