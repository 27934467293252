import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const PrivacyPolicy = () => {

    return (
        <>
            <SEO title="Privacy Policy" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                title="Privacy Policy"
                page="Privacy Policy"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                <div className="section-title">
                                    <h5 className="title">Privacy Policy for Total List - Last updated: December 14, 2023</h5>
                                </div>
                                <p>Katbi.net ("us", "we", or "our") operates the Total List mobile application (the "Service").
                                </p>
                                <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.

We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy.
</p>
                                <h4>Information Collection and Use</h4>
                               <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>
                                <h4>Types of Data Collected</h4>
                                <p className="mb--20">- While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:
</p>
                                <ul>
                                    <li> Email address</li>
                                    <li>First name and last name</li>
                                    <li>Phone number</li>
                                    <li> Address, State, Province, ZIP/Postal code, City</li>
                                </ul>
                                <h4>Usage Data:</h4>
                                <p>We may also collect information on how the Service is accessed and used ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.</p>
                                <h4>Use of Data</h4>
                                <p>Katbi.net uses the collected data for various purposes:
</p>
                                <h4>The use of cookies and web beacons</h4>
                                <p className="mb--20">- To provide and maintain the Service
- To notify you about changes to our Service
- To provide customer care and support
- To monitor the usage of the Service
- To detect, prevent and address technical issues
.</p>
                                <h4>Transfer of Data</h4>
                                <p className="mb--20">Your information, including Personal Data, may be transferred to—and maintained on—computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.

If you are located outside the UAE and choose to provide information to us, please note that we transfer the data, including Personal Data, to the UAE and process it there.

Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.

</p>
                                <h4>Security of Data</h4>
                                <p>The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
                                <h4> Changes to This Privacy Policy</h4>
                                <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.

You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
</p>
                                <h4>Contact Us</h4>
                                <p>If you have any questions about this Privacy Policy, please contact us:

By email: [support@katbi.net](mailto:support@katbi.net)

By visiting this page on our website: [katbi.net/contact](https://katbi.net/contact)
</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CtaLayoutOne />
            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default PrivacyPolicy;