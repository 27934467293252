import React from 'react';

const WhatsAppButton = () => {
  // Add the link to your WhatsApp chat here
  const whatsappLink = 'https://api.whatsapp.com/send?phone=+971505733543&text=Hello Bilal';

  return (
    <a href={whatsappLink} target="_blank" rel="noopener noreferrer" className="whatsapp-button">
      <img
        src={process.env.PUBLIC_URL + "/images/whatsapp-icon.png"} // Replace with the actual path to your WhatsApp icon
        alt="WhatsApp"
        className="whatsapp-icon"
      />
    </a>
  );
};

export default WhatsAppButton;
